import React from "react";
import SeccionUno from "./../SeccionUno/SeccionUno";
import SeccionDos from "./../SeccionDos/SeccionDos";

const Main = () => {
  return (
    <main className="p-4">
      <SeccionUno />
      <SeccionDos />
    </main>
  );
};

export default Main;
