import React from "react";
import logoSoluciones from "../../assets/imagenes/seccionUno/logoSeccionUno.png";

const SeccionUno = () => {
  return (
    <div className="flex flex-col md:flex-row items-center md:items-center justify-center p-4 mb-6">
      {/* Imagen: 1/4 en pantallas grandes y tamaño reducido en pantallas pequeñas */}
      <div className="md:w-1/4 w-full flex justify-center md:justify-start mb-4 md:mb-0">
        <img
          src={logoSoluciones}
          alt="Logo Soluciones Digitales"
          className="w-2/3 md:w-full max-w-full h-auto"
        />
      </div>
      {/* Texto: 3/4 en pantallas grandes y centrado verticalmente */}
      <div className="md:w-3/4 w-full text-left flex items-center">
        <p className="text-lg md:text-2xl font-comic-sans text-justify mr-6 ml-2">
          Soluciones Digitales está conformado por un equipo de profesionales
          que brindan un abordaje interdisciplinario frente a las dinámicas
          actuales.
          <br />
          <br />
          Nos especializamos en Identidad y Diseño visual, Desarrollo de
          Software, Comunicación interna y externa en una empresa, y en la
          Conexión emocional entre un producto o servicio y su público objetivo.
          Nos enfocamos en la Usabilidad y Experiencia de usuario, Marketing
          Digital, Creación de estructuras funcionales, Análisis de rendimiento,
          y el Logro de objetivos.
          <br />
          <br />
          Nuestro objetivo principal es resolver consultas de manera técnica,
          pero también nos permitimos mirar más allá de la digitalidad,
          trabajando con el ser humano y la comunicación en todos los ámbitos.
          Creemos en la importancia de un enfoque integral que contemple tanto
          los aspectos técnicos como emocionales para lograr soluciones
          verdaderamente efectivas.
        </p>
      </div>
    </div>
  );
};

export default SeccionUno;
