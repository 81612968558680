import React from "react";
import imgUno from "../../assets/imagenes/seccionDos/equipoUno.png";
import imgDos from "../../assets/imagenes/seccionDos/equipoDos.png";
import imgTres from "../../assets/imagenes/seccionDos/equipoTres.png";

const SeccionDos = () => {
  return (
    <div className="p-4">
      <h2 className="text-center text-[#00b7aa] text-6xl font-comic-sans font-bold mb-6">
        El Equipo
      </h2>
      <div className="flex flex-col md:flex-row justify-around">
        {/* Card 3 */}
        <div
          id="CardEquipoTres"
          className="flex flex-col items-center order-3 md:order-none group text-[#00b7aa]"
        >
          <a
            href="https://gcarreiras.com.ar"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={imgTres}
              alt="Equipo Tres"
              className="w-full transition-transform transform group-hover:scale-110"
            />
          </a>
          <a className="text-lg font-semibold mb-2 transition-transform transform group-hover:scale-110 group-hover:text-[#00b7aa]">
            Gaston Carreiras
          </a>
          <ul className="list-none text-[#6e6f73] text-center">
            <li>Software Developer</li>
            <li>Web Development</li>
            <li>Mobile Apps</li>
          </ul>
        </div>
        {/* Card 1 */}
        <div
          id="CardEquipoUno"
          className="flex flex-col items-center mb-6 md:mb-0 order-1 md:order-none group text-[#00b7aa]"
        >
          <img
            src={imgUno}
            alt="Equipo Uno"
            className="w-full transition-transform transform group-hover:scale-110"
          />
          <a className="text-lg font-semibold mb-2 transition-transform transform group-hover:scale-110 group-hover:text-[#00b7aa]">
            María Julia Oyhenart
          </a>
          <ul className="list-none text-[#6e6f73] text-center">
            <li>Marketing Digital</li>
            <li>Diseño UxUi</li>
            <li>Comunicación</li>
          </ul>
        </div>
        {/* Card 2 */}
        <div
          id="CardEquipoDos"
          className="flex flex-col items-center mb-6 md:mb-0 order-2 md:order-none group text-[#00b7aa]"
        >
          <a
            href="https://ricardochiani.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={imgDos}
              alt="Equipo Dos"
              className="w-full transition-transform transform group-hover:scale-110"
            />
          </a>
          <a className="text-lg font-semibold mb-2 transition-transform transform group-hover:scale-110 group-hover:text-[#00b7aa]">
            Ricardo Chiani
          </a>
          <ul className="list-none text-[#6e6f73] text-center">
            <li>Coach Profesional</li>
            <li>Master Trainer PNL</li>
            <li>Hipnoterapeuta</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SeccionDos;
