import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";

const DarkMode = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <button
      onClick={() => setDarkMode(!darkMode)}
      className="absolute top-0 right-0 mt-4 mr-4 bg-gray-800 text-white p-2 rounded-full 
                   flex items-center justify-center w-10 h-10 md:w-12 md:h-12"
    >
      <FontAwesomeIcon
        icon={darkMode ? faSun : faMoon}
        size="lg"
        className="w-full h-full"
      />
    </button>
  );
};

export default DarkMode;
