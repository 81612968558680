import React from "react";
import DarkMode from "../DarkMode/DarkMode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faGoogle,
  faWhatsapp,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import imagenHeader from "../../assets/imagenes/header.png";

const Header = () => {
  return (
    <header className="relative w-full p-4 flex flex-col items-center">
      <DarkMode className="absolute top-0 right-0 mt-4 mr-4" />
      <h1 className="sr-only">Soluciones Digitales</h1>
      <img
        srcSet={`${imagenHeader} 320w, ${imagenHeader} 768w`}
        sizes="(max-width: 320px) 320px, (max-width: 768px) 768px, 100vw"
        src={imagenHeader}
        alt="Soluciones Digitales"
        className="w-full md:w-3/4 lg:w-7/12 object-cover mt-8 mb-6"
      />
      <div className="flex space-x-4 mt-6 mb-6">
        <FontAwesomeIcon
          icon={faFacebook}
          className="text-gray-600 dark:text-gray-400 w-6 h-6 md:w-8 md:h-8"
        />
        <FontAwesomeIcon
          icon={faInstagram}
          className="text-gray-600 dark:text-gray-400 w-6 h-6 md:w-8 md:h-8"
        />
        <FontAwesomeIcon
          icon={faLinkedin}
          className="text-gray-600 dark:text-gray-400 w-6 h-6 md:w-8 md:h-8"
        />
        <FontAwesomeIcon
          icon={faGoogle}
          className="text-gray-600 dark:text-gray-400 w-6 h-6 md:w-8 md:h-8"
        />
        <FontAwesomeIcon
          icon={faWhatsapp}
          className="text-gray-600 dark:text-gray-400 w-6 h-6 md:w-8 md:h-8"
        />
        <FontAwesomeIcon
          icon={faTelegram}
          className="text-gray-600 dark:text-gray-400 w-6 h-6 md:w-8 md:h-8"
        />
      </div>
    </header>
  );
};

export default Header;
